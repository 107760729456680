import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';

const PhotoUploader = ({ onPhotoSelect }) => {
  const [file, setFile] = useState(null);
  const [cameraActive, setCameraActive] = useState(false);
  const webcamRef = useRef(null);

  // Set options for the Webcam component
  const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: 'user', // 'user' for front camera, 'environment' for back camera
  };

  // Function to capture a photo from webcam
  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setFile(imageSrc); // Display the captured image
    const imageBlob = dataURLtoBlob(imageSrc);
    onPhotoSelect(imageBlob); // Send the Blob image to the parent component
  };

  // Function to convert data URL to Blob
  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(URL.createObjectURL(selectedFile));
      onPhotoSelect(selectedFile);
    }
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      {!cameraActive ? (
        <button
          onClick={() => setCameraActive(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Aktifkan Kamera
        </button>
      ) : (
        <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            className="border"
          />
          <button onClick={capturePhoto} className="bg-green-500 text-white px-4 py-2 rounded mt-2">
            Tangkap Foto
          </button>
          <button onClick={() => setCameraActive(false)} className="bg-red-500 text-white px-4 py-2 rounded mt-2">
            Matikan Kamera
          </button>
        </>
      )}

      <input type="file" accept="image/*" onChange={handleFileChange} className="border p-2" />
      {file && <img src={file} alt="Selected" className="max-w-xs mt-4" />}
    </div>
  );
};

export default PhotoUploader;
