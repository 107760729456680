import React, { useState } from 'react';
import PhotoUploader from '../components/PhotoUploader';
import ImageEditor from '../components/ImageEditor';
import axiosInstance from '../utils/axiosConfig';

const CapturePage = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);

  const handlePhotoSelect = (file) => {
    setSelectedImage(URL.createObjectURL(file));
  };

  const handleCrop = (croppedImageUrl) => {
    setCroppedImage(croppedImageUrl);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('file', croppedImage);
      const response = await axiosInstance.post('/upload', formData);
      setUploadResponse(response.data);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Capture Page</h1>
      <PhotoUploader onPhotoSelect={handlePhotoSelect} />
      {selectedImage && <ImageEditor src={selectedImage} onCrop={handleCrop} />}
      {croppedImage && (
        <button onClick={handleUpload} className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
          Upload Gambar
        </button>
      )}
      {uploadResponse && (
        <div className="mt-4">
          <h2 className="text-xl">Hasil Upload:</h2>
          <p>{uploadResponse.message}</p>
        </div>
      )}
    </div>
  );
};

export default CapturePage;
