import React, { useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const ImageEditor = ({ src, onCrop }) => {
  const cropperRef = useRef(null);

  const handleCrop = () => {
    const cropper = cropperRef.current.cropper;
    onCrop(cropper.getCroppedCanvas().toDataURL());
  };

  return (
    <div className="flex flex-col items-center">
      {src ? (
        <Cropper
          src={src}
          style={{ height: 400, width: '100%' }}
          initialAspectRatio={1}
          guides={false}
          ref={cropperRef}
        />
      ) : (
        <p>Unggah atau ambil foto terlebih dahulu</p>
      )}
      <button onClick={handleCrop} className="bg-green-500 text-white px-4 py-2 rounded mt-4">
        Crop Gambar
      </button>
    </div>
  );
};

export default ImageEditor;
