import CapturePage from "../../components/CapturePage";

const PageHome = (props) => {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <CapturePage />
    </div>
  );
};

export default PageHome;
